.BottomBar-container {
    /* border: solid red 2px; */
    position: fixed;
    height: 20px;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 10px;
    text-align: center;
    font-size: .8rem;
    color: rgb(114, 114, 114);
    background-color: rgba(0, 0, 0, 0.98);
    z-index: 999999;
    white-space: pre;
}

.BottomBar-text {
    /* border: solid red 2px; */
    overflow-wrap: break-word;
}

.BottomBar-text-small {
    display: none;
}

@media screen and (max-width: 650px) {
    .BottomBar-text {
        display: none;
    }
    .BottomBar-text-small {
        display: contents;
    }
}