* {
  margin: 0;
}

/* non-repeated background */
html {
  text-align: center;
  background: url(../public/images/background0.jpg) no-repeat center center fixed;
  background-color: black;
  background-size: cover;
}

/* repeated background */
/* html {
  text-align: center;
  background: url(../public/images/background10.jpg) center center fixed;
  background-color: black;
  background-size: 500px;
} */

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute!important;
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  clip: rect(1px,1px,1px,1px);
}

.App {
  /* border: 2px rgb(43, 255, 0) solid; */
  text-align: center;
  /* overflow-x: hidden; */
  /* contain: paint; */
}