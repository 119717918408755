.NavBar-container {
    /* border: 2px red solid; */
    height: 80px;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.98);
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999999;
}

.NavBar-left {
    display: flex;
    align-items: center;
    padding-right: .5rem;
    gap: 1rem;
}

.NavBar-name {
    color: white;
    font-size: 2rem;
}

.NavBar-title {
    color: #0ecde0;
    font-size: 1.6rem;
}

.NavLinks-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
    margin-right: 20px;
}

.Link {
    text-decoration: none;
    color: #aaaaaa;
    /* font-size: 1.2rem; */
    transition: ease-in-out 0.2s;
    margin-right: 20px;
}

.Link.active {
    font-size: 1.2rem;
    font-weight: bold;
    color: #0ecde0;
  }